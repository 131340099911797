<template>
	<div class="page">


		<el-tabs v-model="activeName" @tab-click="handleClick">
			<el-tab-pane label="待处理" name="undisposed">

				<Tables ref="xTable" :tableName="tableName" :tableData="tableData" :totalPage="totalPage"
					:isLoading="isLoading" :searchFormData="searchFormData" :showRefresh="true" :showSearch="true"
					:showSeq="false" :showOutput="true" @getList="getList" @clearSearch="clearSearch"
					v-if="activeName == 'undisposed'">
					<!--搜索自定义内容-->

					<el-form-item slot="search-item">
						<el-input size="small" v-model="searchFormData.phone" clearable placeholder="请输入手机号查询"></el-input>
					</el-form-item>
					<el-form-item slot="search-item">
						<el-select size="small" v-model="searchFormData.city" style="width: 120px;" clearable
							placeholder="归属城市">
							<el-option v-for="(item, index) in $userArea" :label="item" :value="index">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item slot="search-item">
						<el-select v-model="searchFormData.type" placeholder="电卡" clearable size="small"
							class="from-item-width">
							<el-option label="金额卡" :value="1">
							</el-option>
							<el-option label="电量卡" :value="2"></el-option>
						</el-select>
					</el-form-item>
					<!--			<el-form-item slot="search-item">-->
					<!--				<el-select size="small" v-model="searchFormData.is_vip" style="width: 120px;" clearable placeholder="会员类型">-->
					<!--					<el-option v-for="(item,index) in $userType" :label="item" :value="index">-->
					<!--					</el-option>-->
					<!--				</el-select>-->
					<!--			</el-form-item>-->
					<el-form-item slot="search-item">
						<el-select v-model="searchFormData.alarm_type" placeholder="建议类型" clearable size="small"
							class="from-item-width">
							<el-option label="vip升级" :value="1">
							</el-option>
							<el-option label="转化vip" :value="2">
							</el-option>
							<el-option label="充电量异常" :value="3">
							</el-option>
							<el-option label="电卡余额不足" :value="4">
							</el-option>
							<el-option label="会员续费" :value="5">
							</el-option>
							<el-option label="用户流失" :value="6">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item slot="search-item">
						<el-date-picker v-model="searchFormData.vip_deadline" style="width: 240px;" clearable
							type="daterange" format="yyyy-MM-dd" value-format="yyyy-MM-dd" start-placeholder="开始vip到期时间"
							end-placeholder="结束vip到期时间" size="small">
						</el-date-picker>
					</el-form-item>
					<el-form-item slot="search-item">
						<el-date-picker v-model="searchFormData.update_time" style="width: 240px;" clearable
							type="daterange" format="yyyy-MM-dd" value-format="yyyy-MM-dd" start-placeholder="开始最后充电时间"
							end-placeholder="结束最后充电时间" size="small">
						</el-date-picker>
					</el-form-item>
					<!--表格内容-->
					<vxe-table-column slot="table-item" field="fans_id" title="用户ID" align="left" min-width="80px"
						class="vxe-cell">
						<template v-slot="{ row }">
							<span>{{ row.fans_id }}</span>
						</template>
					</vxe-table-column>
					<vxe-table-column slot="table-item" field="phone" title="手机号" align="center" width="120px">
						<template v-slot="{ row }">
							<span>{{ row.phone }}</span>
						</template>
					</vxe-table-column>
					<vxe-table-column slot="table-item" field="pay_money" title="总消费" align="center" min-width="80px">
						<template v-slot="{ row }">
							<span>{{ row.pay_money }}</span>
						</template>
					</vxe-table-column>
					<vxe-table-column slot="table-item" field="total_electric" title="总度数" align="center" min-width="80px">
						<template v-slot="{ row }">
							<span>{{ row.total_electric }}</span>
						</template>
					</vxe-table-column>
					<vxe-table-column slot="table-item" field="all_electric" title="当月度数" align="center" min-width="80px">
						<template v-slot="{ row }">
							<span>{{ row.all_electric }}</span>
						</template>
					</vxe-table-column>
					<vxe-table-column slot="table-item" field="cityText" title="归属城市" align="center" min-width="80px">
						<template v-slot="{ row }">
							<span>{{ types.city[row.city] }}</span>
						</template>
					</vxe-table-column>
					<vxe-table-column slot="table-item" field="card" title="电卡" align="center" min-width="100px">
						<template slot-scope="{ row }">
							<a @click="detailCard(row)" style="color:blue;cursor:pointer">{{ row.card }}</a>
						</template>
					</vxe-table-column>
					<vxe-table-column slot="table-item" field="vip" title="Vip类型" align="center" min-width="80px">
						<template v-slot="{ row }">
							<span>{{ row.vip }}</span>
						</template>
					</vxe-table-column>
					<vxe-table-column slot="table-item" field="vip_deadline" title="VIP到期时间" align="center"
						min-width="100px">
						<template v-slot="{ row }">
							<span>{{ row.vip_deadline }}</span>
						</template>
					</vxe-table-column>
					<vxe-table-column slot="table-item" field="group_name" title="充电场站" align="center" min-width="80px">
						<template v-slot="{ row }">
							<span>{{ row.group_name }}</span>
						</template>
					</vxe-table-column>
					<vxe-table-column slot="table-item" field="last_cd_time" title="最后一次充电时间" align="center"
						min-width="100px">
						<template v-slot="{ row }">
							<span>{{ row.last_cd_time }}</span>
						</template>
					</vxe-table-column>

					<vxe-table-column slot="table-item" field="register_time" title="注册时间" align="center" min-width="100px">
						<template v-slot="{ row }">
							<span>{{ row.register_time }}</span>
						</template>
					</vxe-table-column>
					<vxe-table-column slot="table-item" field="alarm_type" title="建议类型" align="center" min-width="80px">
						<template v-slot="{ row }">
							<span>{{ types.alarmtype[row.alarm_type] }}</span>
						</template>
					</vxe-table-column>
					<vxe-table-column slot="table-item" field="last_time" title="创建时间" align="center" min-width="100px">
						<template v-slot="{ row }">
							<span>{{ row.create_time }}</span>
						</template>
					</vxe-table-column>
					<vxe-table-column slot="table-item" title="操作" align="center" width="280">
						<template v-slot="{ row }">
							<el-button size="small" plain @click="doCl(row)" v-if="$hasAccess('user.fans/view')">处理
							</el-button>
							<el-button size="small" plain @click="orderPhone = row.phone, orderDialogShow = true">充电订单
							</el-button>
						</template>
					</vxe-table-column>
				</Tables>



			</el-tab-pane>
			<el-tab-pane label="已处理" name="disposed">
				<Tables ref="xTable" :tableName="tableName" :tableData="tableDataTwo" :totalPage="totalPageTwo"
					:isLoading="isLoadingTwo" :searchFormData="searchFormDataTwo" :showRefresh="true" :showSearch="true"
					:showSeq="false" :showOutput="true" @getList="getList" @clearSearch="clearSearchTwo"
					v-if="activeName == 'disposed'">
					<!--搜索自定义内容-->

					<el-form-item slot="search-item">
						<el-input size="small" v-model="searchFormDataTwo.phone" clearable
							placeholder="请输入手机号查询"></el-input>
					</el-form-item>
					<el-form-item slot="search-item">
						<el-select size="small" v-model="searchFormDataTwo.city" style="width: 120px;" clearable
							placeholder="归属城市">
							<el-option v-for="(item, index) in $userArea" :label="item" :value="index">
							</el-option>
						</el-select>
					</el-form-item>

					<el-form-item slot="search-item">
						<el-select v-model="searchFormDataTwo.alarm_type" placeholder="建议类型" clearable size="small"
							class="from-item-width">
							<el-option label="vip升级" :value="1">
							</el-option>
							<el-option label="转化vip" :value="2">
							</el-option>
							<el-option label="充电量异常" :value="3">
							</el-option>
							<el-option label="电卡余额不足" :value="4">
							</el-option>
							<el-option label="会员续费" :value="5">
							</el-option>
							<el-option label="用户流失" :value="6">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item slot="search-item">
						<el-select v-model="searchFormDataTwo.statuss" placeholder="状态" clearable size="small"
							class="from-item-width">
							<el-option label="已处理" :value="1">
							</el-option>
							<el-option label="待二次处理" :value="2">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item slot="search-item">
						<el-select v-model="searchFormDataTwo.result" placeholder="处理结果" clearable size="small"
							class="from-item-width">
							<el-option label="已处理/已开通" :value="1">
							</el-option>
							<el-option label="转站长" :value="2">
							</el-option>
							<el-option label="追踪中" :value="3">
							</el-option>
							<el-option label="手动结束" :value="4">
							</el-option>
							<el-option label="追踪失败" :value="5">
							</el-option>
						</el-select>
					</el-form-item>

					<!-- <el-form-item slot="search-item">
						<el-select v-model="searchFormDataTwo.one_people" placeholder="第一处理人" clearable size="small"
							class="from-item-width">
							<el-option label="张三" :value="1">
							</el-option>
							<el-option label="李四" :value="2">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item slot="search-item">
						<el-select v-model="searchFormDataTwo.two_people" placeholder="第二处理人" clearable size="small"
							class="from-item-width">
							<el-option label="张三" :value="1">
							</el-option>
							<el-option label="李四" :value="2">
							</el-option>
						</el-select>
					</el-form-item> -->
					<el-form-item slot="search-item">
						<el-date-picker v-model="searchFormDataTwo.update_time" style="width: 240px;" clearable
							type="daterange" format="yyyy-MM-dd" value-format="yyyy-MM-dd" start-placeholder="处理开始时间"
							end-placeholder="处理结束时间" size="small">
						</el-date-picker>
					</el-form-item>
					<!--表格内容-->

					<vxe-table-column slot="table-item" field="fans_id" title="用户ID" align="center" min-width="80px"
						class="vxe-cell">
						<template v-slot="{ row }">
							<span>{{ row.fans_id }}</span>
						</template>
					</vxe-table-column>

					<vxe-table-column slot="table-item" field="phone" title="手机号" align="center" min-width="110px"
						class="vxe-cell">
						<template v-slot="{ row }">
							<span>{{ row.phone }}</span>
						</template>
					</vxe-table-column>

					<vxe-table-column slot="table-item" field="cityText" title="城市" align="center" min-width="80px">
						<template v-slot="{ row }">
							<span>{{ types.city[row.city] }}</span>
						</template>
					</vxe-table-column>

					<vxe-table-column slot="table-item" field="alarm_type" title="建议类型" align="center" min-width="80px">
						<template v-slot="{ row }">
							<span>{{ types.alarmtype[row.alarm_type] }}</span>
						</template>
					</vxe-table-column>

					<vxe-table-column slot="table-item" field="type" title="状态" align="center" min-width="100px"
						class="vxe-cell">
						<template v-slot="{ row }">
							<span>{{ row.type == 1 ? "待二次处理" : "已处理" }}</span>
						</template>
					</vxe-table-column>

					<vxe-table-column slot="table-item" field="one_people" title="第一处理人" align="center" min-width="100px"
						class="vxe-cell">
						<template v-slot="{ row }">
							<span>{{ row.one_people }}</span>
						</template>
					</vxe-table-column>


					<vxe-table-column slot="table-item" field="replyTime1" title="第一处理时间" align="center" min-width="120px"
						class="vxe-cell">
						<template v-slot="{ row }">
							<span>{{ row.one_time }}</span>
						</template>
					</vxe-table-column>

					<vxe-table-column slot="table-item" field="two_people" title="第二处理人" align="center" min-width="100px"
						class="vxe-cell">
						<template v-slot="{ row }">
							<span>{{ row.two_people }}</span>
						</template>
					</vxe-table-column>


					<vxe-table-column slot="table-item" field="replyTime2" title="第二处理时间" align="center" min-width="120px"
						class="vxe-cell">
						<template v-slot="{ row }">
							<span>{{ row.two_time }}</span>
						</template>
					</vxe-table-column>


					<vxe-table-column slot="table-item" field="result" title="处理结果" align="center" min-width="100px"
						class="vxe-cell">
						<template v-slot="{ row }">
							<span>{{ types.status[row.status] }}</span>
						</template>
					</vxe-table-column>

					<vxe-table-column slot="table-item" field="one_content" title="备注1" align="center" min-width="100px"
						class="vxe-cell">
						<template v-slot="{ row }">
							<span>{{ row.one_content }}</span>
						</template>
					</vxe-table-column>

					<vxe-table-column slot="table-item" field="two_content" title="备注2" align="center" min-width="100px"
						class="vxe-cell">
						<template v-slot="{ row }">
							<span>{{ row.two_content }}</span>
						</template>
					</vxe-table-column>

					<vxe-table-column slot="table-item" title="操作" align="center" width="220">
						<template v-slot="{ row }">

<!--							<el-button size="small" plain @click="doCl(row)" v-if="$hasAccess('user.fans/view')&&(row.status==0)">处理-->
<!--							</el-button>-->

							<el-button size="small" plain @click="orderPhone = row.phone, orderDialogShow = true">用户详情
							</el-button>
						</template>
					</vxe-table-column>
				</Tables>
			</el-tab-pane>
		</el-tabs>


		<!-- 订单列表 -->
		<el-dialog title="相关订单" :visible.sync="orderDialogShow" :close-on-click-modal="false" :close-on-press-escape="false"
			width="90%" top="10px">
			<div>
				<div>用户详情</div>
				<useList style="height: 125px;" v-if="orderDialogShow" :phone="orderPhone"></useList>
				<div>充电订单</div>
				<orderList style="height: 600px;" v-if="orderDialogShow" :phone="orderPhone"></orderList>
			</div>
		</el-dialog>
		<!-- 详情 -->
		<el-dialog title="用户详情" :visible.sync="detailDialogShow" :close-on-click-modal="false"
			:close-on-press-escape="false" width="800px">
			<el-form ref="detailForm" label-width="140px" class="form" size="mini">
				<el-row>
					<el-col :xs="{ span: 24, offset: 0 }" :sm="{ span: 10, offset: 1 }">
						<!-- 微信信息 -->
						<div class="el-block">
							<div class="row-title">微信信息</div>
							<el-form-item label="微信昵称：">{{ detailDialogFormData.nickname }}</el-form-item>
							<el-form-item label="微信头像：">
								<img class="avatarD" :src="detailDialogFormData.avatar_url" />
							</el-form-item>
							<el-form-item label="手机号：">{{ detailDialogFormData.phone }}</el-form-item>
							<el-form-item label="性别：">{{ types.gender[detailDialogFormData.gender] || '未知' }}</el-form-item>
							<el-form-item label="地区：">{{ detailDialogFormData.country }},
								{{ detailDialogFormData.province }}, {{ detailDialogFormData.city }}
							</el-form-item>
						</div>
						<!-- 消费信息 -->
						<div class="el-block">
							<div class="row-title">消费信息</div>
							<el-form-item label="可用余额：">{{ detailDialogFormData.balance }}元</el-form-item>
							<el-form-item label="可用赠送金：">{{ detailDialogFormData.gift_money }}元</el-form-item>
							<el-form-item label="总消费：">{{ detailDialogFormData.pay_money }}元</el-form-item>
							<el-form-item label="总充值：">{{ detailDialogFormData.recharge_money }}元</el-form-item>
						</div>
					</el-col>
					<el-col :xs="{ span: 24, offset: 0 }" :sm="{ span: 10, offset: 2 }">
						<!-- 注册信息 -->
						<div class="el-block">
							<div class="row-title">注册信息</div>
							<el-form-item label="来源：">{{ types.from[detailDialogFormData.from] || '未知' }}</el-form-item>
							<el-form-item label="注册设备：">
								{{ detailDialogFormData.device_name }}<br />{{ detailDialogFormData.device_no }}
							</el-form-item>
							<el-form-item label="注册时间：">{{ detailDialogFormData.register_time }}</el-form-item>
							<el-form-item label="最近登录时间：">{{ detailDialogFormData.last_time }}</el-form-item>
							<el-form-item label="代理商：">{{ detailDialogFormData.agent_name || '无' }}</el-form-item>
						</div>
						<!-- 会员信息 -->
						<div class="el-block">
							<div class="row-title">白名单信息</div>
							<!-- <el-form-item label="用户类型：">{{types.type[detailDialogFormData.type]||'未知'}}</el-form-item> -->
							<!-- <el-form-item label="会员等级：">{{types.grade[detailDialogFormData.grade]||'未知'}}</el-form-item>
              <el-form-item label="会员结束时间：">{{detailDialogFormData.end_time}}</el-form-item> -->
							<el-form-item label="白名单：">
								<!-- {{types.is_white[detailDialogFormData.is_white]||'未知'}} -->
								<el-switch v-model="detailDialogFormData.is_white" @change="onFansWhiteChange">
								</el-switch>
							</el-form-item>
						</div>
					</el-col>
				</el-row>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button size="small" plain icon="el-icon-close" @click="closeDialog">取 消 </el-button>
			</div>
		</el-dialog>
		<!-- 处理 -->
		<el-dialog title="处理" :visible.sync="rechargeDialogShow" :close-on-click-modal="false"
			:close-on-press-escape="false" width="500px">
			<el-form :model="rechargeDialogFormData" :rules="rules" ref="rechargeForm" label-width="140px" class="form">
				<el-form-item label="处理方式" prop="balance">
					<el-select v-model="rechargeDialogFormData.type" size="small" clearable placeholder="请选择处理方式">
						<el-option label="转站长" :value="1"></el-option>
						<el-option label="已升级/已开通" :value="2"></el-option>
						<el-option label="自动追踪" :value="3"></el-option>
						<el-option label="手动结束" :value="4"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="备注" prop="remark">
					<el-input v-model="rechargeDialogFormData.remark" controls-position="right"
						style="width: 80%;"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" size="small" plain icon="el-icon-check" @click="saveRecharge()"
					:disabled="editDisabled1" v-if="$hasAccess('report.alarm/doalarm')">处 理 </el-button>
				<el-button size="small" plain icon="el-icon-close" @click="closeDialog">取 消 </el-button>
			</div>
		</el-dialog>
		<!-- 详情 -->
		<el-dialog title="绑定车辆" :visible.sync="detailDialogCarShow" :close-on-click-modal="false"
			:close-on-press-escape="false" width="800px"><template>
				<el-table :data="this.detailCar" border class="data_table">
					<!--表格内容-->
					<el-table-column prop="bname" label="品牌" width="180"></el-table-column>
					<el-table-column prop="qname" label="型号" width="180"></el-table-column>
					<el-table-column prop="car_no" label="车牌" width="180"></el-table-column>
					<el-table-column prop="create_time" label="绑定时间" width="180"></el-table-column>
				</el-table>
			</template>
		</el-dialog>
		<!-- 详情 -->
		<el-dialog title="优惠券" :visible.sync="detailDialogConShow" :close-on-click-modal="false"
			:close-on-press-escape="false" width="800px"><template>
				<el-table :data="this.detailCon" border class="data_table">
					<!--表格内容-->
					<el-table-column prop="coupon_id" label="ID" width="180"></el-table-column>
					<el-table-column prop="coupon_name" label="名称" width="180"></el-table-column>
					<el-table-column prop="start_time" label="开始时间" width="180"></el-table-column>
					<el-table-column prop="end_time" label="结束时间" width="180"></el-table-column>
				</el-table>
			</template>
		</el-dialog>
		<!-- 电卡 -->
		<el-dialog title="优惠券" :visible.sync="detailDialogCardShow" :close-on-click-modal="false"
			:close-on-press-escape="false" width="800px"><template>
				<el-table :data="this.detailCardInfo" border class="data_table">
					<!--表格内容-->
					<el-table-column prop="card_id" label="ID" width="90"></el-table-column>
					<el-table-column prop="card_name" label="电卡名称" width="180"></el-table-column>
					<el-table-column prop="card_balance" label="余额" width="90"></el-table-column>
					<el-table-column prop="start_time" label="开始时间" width="180"></el-table-column>
					<el-table-column prop="end_time" label="结束时间" width="180"></el-table-column>
				</el-table>
			</template>
		</el-dialog>
	</div>
</template>
<script>
import Tables from '@/components/tables'
import orderList from '@/components/tableList/orderList'
import useList from '@/components/tableList/useList'
export default {
	name: 'user-fans-list',
	components: {
		Tables,
		orderList,
		useList
	},
	data() {
		return {
			// 表格信息
			tableName: '用户列表',
			isLoading: false,
			isLoadingTwo: false,
			tableData: [],
			tableDataTwo: [],
			totalPage: 0,
			totalPageTwo: 0,
			groupList: [],
			groupLoading: "",
			orderPhone: "",
			// searchType: {
			// 	name: {
			// 		value: 10,
			// 		desc: "请输入微信名查询"
			// 	},
			// 	id: {
			// 		value: 20,
			// 		desc: "请输入用户ID查询 "
			// 	}
			// },
			searchFormData: {
				status: 1
			},
			searchFormDataTwo: {
				status: 2
			},
			types: {
				gender: {
					1: '男',
					2: '女'
				},
				is_white: {
					10: '否',
					20: '是'
				},
				type: {
					10: '临时',
					20: '会员'
				},
				grade: {
					10: '月',
					20: '年'
				},
				city: {
					10: '全部',
					20: '苏州',
					30: '无锡'
				},
				viptype: {
					10: '非vip',
					20: '月卡',
					30: '季卡',
					40: '半年卡',
					50: '年卡'
				},
				alarmtype: {
					1: 'vip升级',
					2: '转化vip',
					3: '充电量异常',
					4: '电卡余额不足',
					5: '会员续费',
					6: '用户流失'
				},
				from: {
					miniprograms: '微信小程序'
				},
				result: {
					1: "已处理/已开通",
					2: "转站长",
					3: "追踪中",
					4: "手动结束"
				},
				status: {
					1: "追踪中",
					6: "手动结束",
					3: "转站长",
					5: "追踪失败",
					4: "已处理/已开通"
				}
			},
			activeName: "undisposed",
			// 详情
			detailDialogShow: false,
			detailDialogFormData: {},
			//车牌
			detailDialogCarShow: false,
			detailCar: [],
			//优惠券
			detailDialogConShow: false,
			detailCon: [],
			//电卡
			detailDialogCardShow: false,
			detailCardInfo: [],
			// 充值
			rechargeDialogShow: false,
			rechargeDialogFormData: {},
			rechargeDialogFormOriData: {},
			orderDialogShow: false,
			rules: {
				status: [{
					required: true,
					message: '请选择处理方式',
					trigger: 'change'
				}],
				gift_money: [{
					required: true,
					message: '请输入赠送金额',
					trigger: 'change'
				}]
			},
			// 扣款
			chargingDialogShow: false,
			chargingDialogFormData: {},
			chargingDialogFormOriData: {},
			chargingRules: {
				balance: [{
					required: true,
					message: '请选择处理方式',
					trigger: 'change'
				}],
				gift_money: [{
					required: true,
					message: '请输入赠送金额',
					trigger: 'change'
				}]
			}
		}
	},
	mounted: function () {
		//this.searchFormData.searchType = this.searchType.name.value;
		//this.getGroupList();
	},
	computed: {
		editDisabled1() {
			if (this.rechargeDialogFormData.type == '') {
				return true
			}
			return false
		},
	},
	methods: {
		handleClick(tab, event) {

		},
		// 表格列表
		async getList(currentPage, pageSize) {
			if (this.activeName == "undisposed") {
				const params = {
					token: this.$store.state.user.token,
					page: currentPage,
					size: pageSize
				}
				this.isLoading = true
				const searchData = this.searchFormData
				// 小区限制
				// const group_id = this.$store.state.user.userInfo.group_id
				// if (!searchData.group_id && group_id) {
				// 	searchData.group_id = group_id
				// }
				const res = await this.$api.Report.GetAlarmList(params, searchData)
				this.tableData = res.data;
				this.totalPage = res.total
				this.isLoading = false;
			}
			else if (this.activeName == "disposed") {
				const params = {
					token: this.$store.state.user.token,
					page: currentPage,
					size: pageSize
				}
				this.isLoadingTwo = true
				const searchData = this.searchFormDataTwo
				// 小区限制
				// const group_id = this.$store.state.user.userInfo.group_id
				// if (!searchData.group_id && group_id) {
				// 	searchData.group_id = group_id
				// }
				const res = await this.$api.Report.GetAlarmList(params, searchData)
				this.tableDataTwo = res.data;
				this.totalPageTwo = res.total
				this.isLoadingTwo = false;

			}
			setTimeout(() => {
				let divs = document.getElementsByClassName("vxe-table--body-wrapper");
				divs[0].style.height = '100%';
				divs[1].style.height = '100%';

			}, 200);
		},
		// 重置搜索
		clearSearch() {
			this.searchFormData = {
				status: 1
			}
		},
		clearSearchTwo() {
			this.searchFormDataTwo = { status: 2 }
		},
		// 关闭弹窗
		closeDialog() {
			this.detailDialogShow = false
			this.rechargeDialogShow = false
			this.chargingDialogShow = false
		},
		// 详情
		async detailOne(row) {
			const params = {
				token: this.$store.state.user.token,
				fans_id: row.fans_id
			}
			const res = await this.$api.Fans.GetFansDetail(params)
			res.is_white = res.is_white === 20;
			this.detailDialogFormData = res;
			console.log(this.detailDialogFormData)
			this.detailDialogShow = true;
		},
		async detailCars(row) {
			const params = {
				token: this.$store.state.user.token,
				fans_id: row.fans_id
			}
			const res = await this.$api.Fans.GetCarDetail(params)
			this.detailCar = res
			this.detailDialogCarShow = true
		},
		async detailCoupon(row) {
			const params = {
				token: this.$store.state.user.token,
				fans_id: row.fans_id
			}
			const res = await this.$api.Fans.GetConDetail(params)
			this.detailCon = res
			this.detailDialogConShow = true
		},
		async detailCard(row) {
			const params = {
				token: this.$store.state.user.token,
				fans_id: row.fans_id
			}
			const res = await this.$api.Fans.GetCardDetail(params)
			this.detailCardInfo = res
			this.detailDialogCardShow = true
		},
		// 处理报警
		doCl(row) {
			this.rechargeDialogFormData = {
				id: row.id
			}
			this.rechargeDialogFormOriData = {
				nickname: row.nickname
			}
			if (this.$refs.rechargeForm) {
				this.$refs.rechargeForm.resetFields()
			}
			this.rechargeDialogShow = true
		},
		// 处理报警
		saveRecharge() {
			this.$refs.rechargeForm.validate(async (valid) => {
				if (valid) {
					const params = {
						token: this.$store.state.user.token,
						form: JSON.stringify(this.rechargeDialogFormData)
					}
					await this.$api.Report.DoAlarm(params)
					this.$notify({
						title: '成功',
						message: '处理成功',
						type: 'success'
					})
					this.closeDialog()
					this.$refs.xTable.refreshTable()
				}
			})
		},
		// 扣款
		async chargingOne(row) {
			const params = {
				token: this.$store.state.user.token,
				fans_id: row.fans_id
			}
			const res = await this.$api.Fans.GetFansDetail(params)
			this.chargingDialogFormOriData = res
			this.chargingDialogFormData = {
				fans_id: row.fans_id,
				balance: 0,
				gift_money: 0
			}
			if (this.$refs.chargingForm) {
				this.$refs.chargingForm.resetFields()
			}
			this.chargingDialogShow = true
		},
		// 扣款保存
		saveCharging() {
			this.$refs.chargingForm.validate(async (valid) => {
				if (valid) {
					const params = {
						token: this.$store.state.user.token,
						form: JSON.stringify(this.chargingDialogFormData)
					}
					await this.$api.Fans.ChargingFans(params)
					this.$notify({
						title: '成功',
						message: '扣款成功',
						type: 'success'
					})
					this.closeDialog()
					this.$refs.xTable.refreshTable()
				}
			})
		},
		userFansStatusOper: async function (row) {
			let res = {}
			const params = {
				token: this.$store.state.user.token,
				fans_id: row.fans_id
			}
			if (row.status == 1) {
				res = await this.$api.Fans.DisableFans(params)
			} else {
				res = await this.$api.Fans.EnableFans(params)
			}
			console.log(res)
			let title = '成功'
			let type = 'success'
			if (res.indexOf('成功') == -1) {
				title = '失败'
				type = 'error'
			}
			this.$notify({
				title: title,
				message: res,
				type: type
			})
			this.$refs.xTable.refreshTable()
		},
		onFansWhiteChange: async function () {
			const params = {
				token: this.$store.state.user.token,
				fans_id: this.detailDialogFormData.fans_id,
				is_white: this.detailDialogFormData.is_white ? 20 : 10
			}
			let res = await this.$api.Fans.SetFansIsWhite(params)
			let title = res;
			let type = 'success'
			if (res.indexOf('成功') == -1) {
				type = 'error'
			}
			this.$notify({
				title: title,
				message: res,
				type: type
			})
			this.$refs.xTable.refreshTable()
		}
	}
}
</script>

<style lang="scss" scoped>
body {
	:deep(.toolbar) {
		display: none;
	}
}



.row-title {
	font-size: 14px;
	line-height: 20px;
	padding: 4px 0;
	margin-bottom: 18px;

	&:before {
		content: " ";
		width: 5px;
		height: 20px;
		background: #008e4d;
		display: block;
		float: left;
		margin: 0 10px 0 0;
	}
}

.avatar {
	width: 24px;
	height: 24px;
	display: block;
	margin: 0 auto;
}

.avatarD {
	width: 24px;
	height: 24px;
	display: block;
}

.txt-disable {
	color: #ddd;
}

.avatar-disable {
	filter: grayscale(100%);
}

.vxe-table--render-default .vxe-table--empty-placeholder {
	display: none !important;
}
</style>
<style lang="scss"></style>