<template>
    <Tables ref="xTable" :tableName="tableName" :tableData="tableData" :totalPage="totalPage" :isLoading="isLoading"
            :searchFormData="searchFormData" :showRefresh="false" :showSearch="false" @getList="getList" :showOutput="false"
            :showToolbar="false" @clearSearch="clearSearch">
            <!--表格内容-->
            <vxe-table-column slot="table-item" field="order_no" title="订单号" align="center" min-width="100px" />
            <vxe-table-column slot="table-item" field="u_type_text" title="用户信息" align="center" min-width="100px">
                <template v-slot="{ row }">
                    <span v-if="row.u_type == 10">{{ row.phone || '' }}</span>
                    <span v-if="row.u_type == 20">电卡-{{ row.cardno }}</span>
                </template>
            </vxe-table-column>
            <vxe-table-column slot="table-item" field="device_id_text" title="消费设备" align="center" min-width="100px">
                <template v-slot="{ row }">
                    <div v-if="!row.device_id">无</div>
                    <div v-if="row.device_id">{{ row.device_name }}</div>
                    <div class="vxe-sub" v-if="row.device_id">编号:{{ row.device_no }}-{{ row.plug_num }}</div>
                </template>
            </vxe-table-column>
            <vxe-table-column slot="table-item" field="city" title="消费地区" align="center" min-width="80px" />
            <vxe-table-column slot="table-item" field="group_name" title="消费站点" align="center" min-width="80px" />
            <vxe-table-column slot="table-item" field="pay_type_text" title="支付类型" align="center" min-width="80px">
                <template v-slot="{ row }">
                    <!--span>{{types.pay_type[row.pay_type] + (row.cardno ? '-在线卡' : '')||'未知类型'}}</span-->
                    <span>{{ $payType[row.pay_type] || '未知类型' }}</span>
                </template>
            </vxe-table-column>
            <vxe-table-column slot="table-item" field="pay_source" title="支付方式" align="center" min-width="80px">
                <template v-slot="{ row }">
                    {{ $paySource[row.order_ly] }}
                    <!--span v-if="row.order_ly==10">微信小程序</span>
					<span v-if="row.order_ly==20">支付宝小程序</span>
					<span v-if="row.order_ly==50">微信APP</span>
					<span v-if="row.order_ly==60">支付宝APP</span-->
                </template>
            </vxe-table-column>
            <!--			<vxe-table-column slot="table-item" field="after_balance" title="账户余额" align="center" min-width="80px" />-->
            <vxe-table-column slot="table-item" field="order_price" title="订单金额" align="center" min-width="80px" />
            <vxe-table-column slot="table-item" field="pay_price" title="实际付款金额" align="center" min-width="80px" />
            <vxe-table-column slot="table-item" field="yh_price_text" title="优惠金额" align="center" min-width="40px">
                <template v-slot="{ row }">
                    <el-tooltip placement="top">
                        <div slot="content">
                            场站:{{ row.yh_group }}<br />
                            优惠券:{{ row.yh_coupon }}<br />
                            VIP:{{ row.yh_vip }}<br />
                        </div>
                        <span>{{ row.yh_price }}</span>
                    </el-tooltip>
                </template>
            </vxe-table-column>
            <vxe-table-column slot="table-item" field="pay_status_text" title="支付状态" align="center" min-width="80px">
                <template v-slot="{ row }">
                    <span>{{ types.pay_status[row.pay_status] || '未知状态' }}</span>
                </template>
            </vxe-table-column>
            <vxe-table-column slot="table-item" field="electric" title="实际用电量" align="center" width="60px" />
            <vxe-table-column slot="table-item" field="qk_type_text" title="枪口类型" align="center" width="60px" />
            <vxe-table-column slot="table-item" field="duration_text" title="实际时长" align="center" min-width="80px">
                <template v-slot="{ row }">
                    <span>{{ $common.secondToTime(row.duration) }}</span>
                </template>
            </vxe-table-column>
            <vxe-table-column slot="table-item" field="start_time" title="充电开始时间" align="center" width="100px" />
            <vxe-table-column slot="table-item" field="end_time" title="充电结束时间" align="center" width="100px" />
            <!--vxe-table-column slot="table-item" field="pay_time" title="付款时间" align="center" width="100px" /-->
            <vxe-table-column slot="table-item" field="order_status_text" title="订单状态" align="center" min-width="80px">
                <template v-slot="{ row }">
                    <span>{{ types.order_status[row.order_status] || '未知状态' }}</span>
                </template>
            </vxe-table-column>
            <vxe-table-column slot="table-item" field="coupon_id" title="优惠券" align="center" width="50px" />
            <vxe-table-column slot="table-item" field="is_vip" title="是否VIP" align="center" width="50px" />
        </Tables>
</template>

<script>
import Tables from '@/components/tables'
export default {
    name: 'user-order-list',
    components: {
        Tables
    },
    props: {
        phone: {
            type: String,
            default() {
                return ''
            }
        },
    },
    data() {
        return {
            // 表格信息
            tableName: '消费列表',
            isLoading: false,
            tableData: [],
            totalPage: 0,
            searchFormData: {

            },
            types: {
                pay_status: {
                    10: '未支付',
                    20: '已支付',
                    30: '退款',
                    50: '无需支付'
                },
                pay_type: {
                    10: '钱包',
                    20: '在线卡',
                    21: '在线卡',
                    30: '先付后充',
                    40: '赠送金',
                    50: '信用分',
                    60: '企业卡',
                    61: '电卡'
                },
                order_status: {
                    10: '进行中',
                    20: '已取消',
                    30: '已完成'
                },
                unusual_status: {
                    10: '正常',
                    20: '功率异常',
                    30: '插座脱落',
                    40: '信号异常'
                },
                is_auto: {
                    10: '是',
                    20: '否'
                },
                share_status: {
                    10: '不结算',
                    20: '未结算',
                    30: '已结算'
                }
            }
        }
    },
    mounted() {
        this.$refs.xTable.exportTable = () => {
            const params = {
                token: this.$store.state.user.token,
                page: 1,
                size: this.totalPage + 1
            }
            this.searchFormData = { phone: this.phone };
            const searchData = this.searchFormData
            // 小区限制
            const group_id = this.$store.state.user.userInfo.group_id
            if (!searchData.group_id && group_id) {
                searchData.group_id = group_id
            }
            this.$api.Fans.GetOrderList(params, searchData).then(res => {
                for (let v of res.data) {
                    v.pay_status_text = this.types.pay_status[v.pay_status];
                    if (v.u_type == 10) {
                        v.u_type_text = v.phone || '';
                    } else if (v.u_type == 20) {
                        v.u_type_text = '电卡-' + v.cardno;
                    }
                    if (!v.device_id) {
                        v.device_id_text = '无';
                    } else {
                        v.device_id_text = v.device_name;
                        v.device_id_text += ' 编号:' + v.device_no + '-' + v.plug_num;
                    }
                    v.pay_type_text = this.$payType[v.pay_type] + (v.cardno ? '-在线卡' : '') || '未知类型';
                    v.pay_source = this.$paySource[v.order_ly];
                    v.yh_price_text = (v.yh_price || 0) + ' 场站:' + (v.yh_group || 0) + ' 优惠券:' + (v.yh_coupon || 0) + ' VIP:' + (v.yh_vip || 0);
                    v.duration_text = this.$common.secondToTime(v.duration);
                    v.qk_type_text = this.$deviceType[v.qk_type];
                    v.order_status_text = this.types.order_status[v.order_status] || '未知状态';
                }
                this.$refs.xTable.exportTableAll(res.data)
            }).catch(err => { });
        }
    },
    methods: {
        // 表格列表
        async getList(currentPage, pageSize) {
            const params = {
                token: this.$store.state.user.token,
                page: currentPage,
                size: pageSize
            }
            this.isLoading = true
            this.searchFormData = { phone: this.phone };
            const searchData = this.searchFormData
            // 小区限制
            const group_id = this.$store.state.user.userInfo.group_id
            if (!searchData.group_id && group_id) {
                searchData.group_id = group_id
            }
            const res = await this.$api.Fans.GetOrderList(params, searchData)
            this.tableData = res.data;
            for (let v of this.tableData) {
                v.pay_status_text = this.types.pay_status[v.pay_status];
                if (v.u_type == 10) {
                    v.u_type_text = v.phone || '';
                } else if (v.u_type == 20) {
                    v.u_type_text = '电卡-' + v.cardno;
                }
                if (!v.device_id) {
                    v.device_id_text = '无';
                } else if (v.device_id) {
                    v.device_id_text = v.device_name;
                } else {
                    v.device_id_text = '编号:' + v.device_no + '-' + v.plug_num;
                }
                v.pay_type_text = this.$payType[v.pay_type] + (v.cardno) || '未知类型';
                v.pay_source = this.$paySource[v.order_ly];
                v.yh_price_text = (v.yh_price || 0) + ' 场站:' + (v.yh_group || 0) + ' 优惠券:' + (v.yh_coupon || 0) + ' VIP:' + (v.yh_vip || 0);
                v.duration_text = this.$common.secondToTime(v.duration);
                v.qk_type_text = this.$deviceType[v.qk_type];
            }
            this.totalPage = res.total
            this.isLoading = false
        },
        // 列表格式化
        formatType({
            cellValue
        }) {
            if (cellValue == 'server') {
                return '服务器 => 硬件'
            }
            if (cellValue == 'client') {
                return '硬件 => 服务器'
            }
            return '未知'
        },

    }
}
</script>

<style lang="scss" scoped>
body {
    :deep(.tables) {
        display: none;
    }
}

.row-title {
    font-size: 14px;
    line-height: 20px;
    padding: 4px 0;
    margin-bottom: 18px;

    &:before {
        content: " ";
        width: 5px;
        height: 20px;
        background: #008e4d;
        display: block;
        float: left;
        margin: 0 10px 0 0;
    }
}
</style>
