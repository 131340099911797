<template>
    <Tables ref="xTable" :tableName="tableName" :tableData="tableData" :totalPage="totalPage" :isLoading="isLoading"
            :searchFormData="searchFormData" :showRefresh="false" :showSearch="false" @getList="getList" :showOutput="false"
            :showToolbar="false" @clearSearch="clearSearch">

           	<vxe-table-column slot="table-item" field="phone" title="手机号" align="center" min-width="110px"
						class="vxe-cell">
						<template v-slot="{ row }">
							<span>{{ row.phone }}</span>
						</template>
					</vxe-table-column>
<vxe-table-column slot="table-item" field="pay_money" title="总消费" align="center" min-width="80px">
						<template v-slot="{ row }">
							<span>{{ row.pay_money }}</span>
						</template>
					</vxe-table-column>
<vxe-table-column slot="table-item" field="total_electric" title="总度数" align="center" min-width="80px">
						<template v-slot="{ row }">
							<span>{{ row.total_electric }}</span>
						</template>
					</vxe-table-column>
                    <vxe-table-column slot="table-item" field="all_electric" title="当月度数" align="center" min-width="80px">
						<template v-slot="{ row }">
							<span>{{ row.all_electric }}</span>
						</template>
					</vxe-table-column>
                    <vxe-table-column slot="table-item" field="cityText" title="归属城市" align="center" min-width="80px">
						<template v-slot="{ row }">
							<span>{{ types.city[row.city] }}</span>
						</template>
					</vxe-table-column>
                    	<vxe-table-column slot="table-item" field="card" title="电卡" align="center" min-width="100px">
						<template slot-scope="{ row }">
							{{ row.card }}
						</template>
					</vxe-table-column>
                    <vxe-table-column slot="table-item" field="vip" title="Vip类型" align="center" min-width="80px">
						<template v-slot="{ row }">
							<span>{{ row.vip }}</span>
						</template>
					</vxe-table-column>
                    <vxe-table-column slot="table-item" field="vip_deadline" title="VIP到期时间" align="center"
						min-width="100px">
						<template v-slot="{ row }">
							<span>{{ row.vip_deadline }}</span>
						</template>
					</vxe-table-column>
                    <vxe-table-column slot="table-item" field="group_name" title="充电场站" align="center" min-width="80px">
						<template v-slot="{ row }">
							<span>{{ row.group_name }}</span>
						</template>
					</vxe-table-column>
					<vxe-table-column slot="table-item" field="last_cd_time" title="最后一次充电时间" align="center"
						min-width="100px">
						<template v-slot="{ row }">
							<span>{{ row.last_cd_time }}</span>
						</template>
					</vxe-table-column>

					<vxe-table-column slot="table-item" field="register_time" title="注册时间" align="center" min-width="100px">
						<template v-slot="{ row }">
							<span>{{ row.register_time }}</span>
						</template>
					</vxe-table-column>
        </Tables>

</template>
<script>
import Tables from '@/components/tables'
export default {
    name: 'user-fans-list',
    components: {
        Tables
    },
    props: {
        phone: {
            type: String,
            default() {
                return ''
            }
        },
    },
    data() {
        return {
            // 表格信息
            tableName: '用户列表',
            isLoading: false,
            tableData: [],
            totalPage: 0,
            groupList: [],
            groupLoading: "",
            // searchType: {
            // 	name: {
            // 		value: 10,
            // 		desc: "请输入微信名查询"
            // 	},
            // 	id: {
            // 		value: 20,
            // 		desc: "请输入用户ID查询 "
            // 	}
            // },

            searchFormData: {},
            types: {
                gender: {
                    1: '男',
                    2: '女'
                },
                is_white: {
                    10: '否',
                    20: '是'
                },
                type: {
                    10: '临时',
                    20: '会员'
                },
                grade: {
                    10: '月',
                    20: '年'
                },
                city: {
                    10: '全部',
                    20: '苏州',
                    30: '无锡'
                },
                from: {
                    miniprograms: '微信小程序'
                }
            },
            // 详情
            detailDialogShow: false,
            detailDialogFormData: {},
            //车牌
            detailDialogCarShow: false,
            detailCar: [],
            //优惠券
            detailDialogConShow: false,
            detailCon: [],
            //电卡
            detailDialogCardShow: false,
            detailCardInfo: [],
            // 充值
            rechargeDialogShow: false,
            rechargeDialogFormData: {},
            rechargeDialogFormOriData: {},
            rules: {
                balance: [{
                    required: true,
                    message: '请输入充值金额',
                    trigger: 'change'
                }],
                gift_money: [{
                    required: true,
                    message: '请输入赠送金额',
                    trigger: 'change'
                }]
            },
            // 扣款
            chargingDialogShow: false,
            chargingDialogFormData: {},
            chargingDialogFormOriData: {},
            chargingRules: {
                balance: [{
                    required: true,
                    message: '请输入充值金额',
                    trigger: 'change'
                }],
                gift_money: [{
                    required: true,
                    message: '请输入赠送金额',
                    trigger: 'change'
                }]
            }
        }
    },
    mounted: function () {
        //this.searchFormData.searchType = this.searchType.name.value;
        //this.getGroupList();
    },
    computed: {
        editDisabled1() {
            if (this.rechargeDialogFormData.balance == 0 && this.rechargeDialogFormData.gift_money == 0) {
                return true
            }
            return false
        },
        editDisabled2() {
            if (this.chargingDialogFormData.balance == 0 && this.chargingDialogFormData.gift_money == 0) {
                return true
            }
            return false
        }
    },
    methods: {
        // 表格列表
        async getList(currentPage, pageSize) {
            const params = {
					token: this.$store.state.user.token,
					page: currentPage,
					size: pageSize
				}
				this.isLoading = true;
                this.searchFormData.phone=this.phone;
				const searchData = this.searchFormData
				// 小区限制
				// const group_id = this.$store.state.user.userInfo.group_id
				// if (!searchData.group_id && group_id) {
				// 	searchData.group_id = group_id
				// }
				const res = await this.$api.Report.GetAlarmList(params, searchData)
				this.tableData = res.data;
				this.totalPage = res.total
				this.isLoading = false;
        },
        // 获取小区信息
        async getGroupList() {
            const params = {
                token: this.$store.state.user.token,
                page: 1,
                size: 1000,
            };
            const res = await this.$api.Device.GetGroupList(params, {});
            console.log("2222");
            this.groupList = res.data;
        },
        // 小区
        async remoteGroup(query) {
            if (query !== '') {
                this.groupLoading = true
                const params = {
                    token: this.$store.state.user.token
                }
                const res = await this.$api.Device.GetGroupList(params, {
                    group_name: query
                })
                this.groupList = res.data
                this.groupLoading = false
            } else {
                this.groupList = []
            }
        },
        // 重置搜索
        clearSearch() {
            this.searchFormData = {}
        },
        // 关闭弹窗
        closeDialog() {
            this.detailDialogShow = false
            this.rechargeDialogShow = false
            this.chargingDialogShow = false
        },
        // 详情
        async detailOne(row) {
            const params = {
                token: this.$store.state.user.token,
                fans_id: row.fans_id
            }
            const res = await this.$api.Fans.GetFansDetail(params)
            res.is_white = res.is_white === 20;
            this.detailDialogFormData = res;
            console.log(this.detailDialogFormData)
            this.detailDialogShow = true;
        },
        async detailCars(row) {
            const params = {
                token: this.$store.state.user.token,
                fans_id: row.fans_id
            }
            const res = await this.$api.Fans.GetCarDetail(params)
            this.detailCar = res
            this.detailDialogCarShow = true
        },
        async detailCoupon(row) {
            const params = {
                token: this.$store.state.user.token,
                fans_id: row.fans_id
            }
            const res = await this.$api.Fans.GetConDetail(params)
            this.detailCon = res
            this.detailDialogConShow = true
        },
        async detailCard(row) {
            const params = {
                token: this.$store.state.user.token,
                fans_id: row.fans_id
            }
            const res = await this.$api.Fans.GetCardDetail(params)
            this.detailCardInfo = res
            this.detailDialogCardShow = true
        },
        // 充值
        rechargeOne(row) {
            this.rechargeDialogFormData = {
                fans_id: row.fans_id,
                balance: 0,
                gift_money: 0
            }
            this.rechargeDialogFormOriData = {
                nickname: row.nickname
            }
            if (this.$refs.rechargeForm) {
                this.$refs.rechargeForm.resetFields()
            }
            this.rechargeDialogShow = true
        },
        // 充值保存
        saveRecharge() {
            this.$refs.rechargeForm.validate(async (valid) => {
                if (valid) {
                    const params = {
                        token: this.$store.state.user.token,
                        form: JSON.stringify(this.rechargeDialogFormData)
                    }
                    await this.$api.Fans.RechargeFans(params)
                    this.$notify({
                        title: '成功',
                        message: '充值成功',
                        type: 'success'
                    })
                    this.closeDialog()
                    this.$refs.xTable.refreshTable()
                }
            })
        },
        // 扣款
        async chargingOne(row) {
            const params = {
                token: this.$store.state.user.token,
                fans_id: row.fans_id
            }
            const res = await this.$api.Fans.GetFansDetail(params)
            this.chargingDialogFormOriData = res
            this.chargingDialogFormData = {
                fans_id: row.fans_id,
                balance: 0,
                gift_money: 0
            }
            if (this.$refs.chargingForm) {
                this.$refs.chargingForm.resetFields()
            }
            this.chargingDialogShow = true
        },
        // 扣款保存
        saveCharging() {
            this.$refs.chargingForm.validate(async (valid) => {
                if (valid) {
                    const params = {
                        token: this.$store.state.user.token,
                        form: JSON.stringify(this.chargingDialogFormData)
                    }
                    await this.$api.Fans.ChargingFans(params)
                    this.$notify({
                        title: '成功',
                        message: '扣款成功',
                        type: 'success'
                    })
                    this.closeDialog()
                    this.$refs.xTable.refreshTable()
                }
            })
        },
        userFansStatusOper: async function (row) {
            let res = {}
            const params = {
                token: this.$store.state.user.token,
                fans_id: row.fans_id
            }
            if (row.status == 1) {
                res = await this.$api.Fans.DisableFans(params)
            } else {
                res = await this.$api.Fans.EnableFans(params)
            }
            console.log(res)
            let title = '成功'
            let type = 'success'
            if (res.indexOf('成功') == -1) {
                title = '失败'
                type = 'error'
            }
            this.$notify({
                title: title,
                message: res,
                type: type
            })
            this.$refs.xTable.refreshTable()
        },
        onFansWhiteChange: async function () {
            const params = {
                token: this.$store.state.user.token,
                fans_id: this.detailDialogFormData.fans_id,
                is_white: this.detailDialogFormData.is_white ? 20 : 10
            }
            let res = await this.$api.Fans.SetFansIsWhite(params)
            let title = res;
            let type = 'success'
            if (res.indexOf('成功') == -1) {
                type = 'error'
            }
            this.$notify({
                title: title,
                message: res,
                type: type
            })
            this.$refs.xTable.refreshTable()
        }
    }
}
</script>
<style lang="scss" scoped>
.row-title {
    font-size: 14px;
    line-height: 20px;
    padding: 4px 0;
    margin-bottom: 18px;

    &:before {
        content: " ";
        width: 5px;
        height: 20px;
        background: #008e4d;
        display: block;
        float: left;
        margin: 0 10px 0 0;
    }
}

.avatar {
    width: 24px;
    height: 24px;
    display: block;
    margin: 0 auto;
}

.avatarD {
    width: 24px;
    height: 24px;
    display: block;
}

.txt-disable {
    color: #ddd;
}

.avatar-disable {
    filter: grayscale(100%);
}
</style>
